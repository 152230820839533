//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Events } from '@/services/api.service';
import { DATE_FORMAT } from '@/services/dateFormat.js';
import {
  isBrowserLocale24h,
  getDayNames,
  getMonthNames,
} from '@/services/helpers';
import * as moment from 'moment-timezone';

export default {
  name: 'DateComponent',
  props: ['errors', 'event', 'is-editing'],
  emits: ['recurrency-validation', 'recurrency-string', 'recurrency-events'],
  components: {},
  data() {
    return {
      inProgress: false,
      isValidRecurrent: false,
      recurrentEventComputedData: {
        dates: [],
        count: 0,
      },
      recurrentTypeOptions: [
        { name: this.$i18n.t('events.create.recurrency_daily'), code: 'DAILY' },
        {
          name: this.$i18n.t('events.create.recurrency_weekly'),
          code: 'WEEKLY',
        },
        {
          name: this.$i18n.t('events.create.recurrency_monthly'),
          code: 'MONTHLY',
        },
      ],
      recurrentPresetOptions: [
        { name: this.$i18n.t('events.create.recurrency_daily'), code: 'DAILY' },
        {
          name: this.$i18n.t('events.create.recurrency_weekly'),
          code: 'WEEKLY',
        },
        {
          name: this.$i18n.t('events.create.recurrency_monthly'),
          code: 'MONTHLY',
        },
        {
          name: this.$i18n.t('events.create.recurrency_custom'),
          code: 'CUSTOM',
        },
      ],
      recurrentFrequencyOptions: [{ name: null, code: null }],
      recurrentMonthlyOptions: [{ name: null, code: null }],
      recurrentDailyOptions: [
        { name: this.$i18n.t('common.yes'), code: true },
        { name: this.$i18n.t('common.no'), code: false },
      ],
      recurrentWeeklyOptions: [{ name: null, code: null }],
      recurrentMonthlyDateOptions: [{ name: null, code: null }],
      recurrentMonthlyEachOptions: [{ name: null, code: null }],

      showRecurrentPreviewModal: false,

      isBrowserLocale24h: isBrowserLocale24h(),
      locale: {
        days: getDayNames(this.$i18n.locale),
        daysShort: getDayNames(this.$i18n.locale, 'short'),
        months: getMonthNames(this.$i18n.locale),
        monthsShort: getMonthNames(this.$i18n.locale, 'short'),
        firstDayOfWeek: 1, /// !!!! todo ?
      },
    };
  },
  methods: {
    ensureDateFrom() {
      if (!this.event.dateFrom)
        this.event.dateFrom = moment().format(DATE_FORMAT);
    },
    ensureDateTo() {
      if (!this.event.dateTo) {
        if (this.event.dateFrom)
          return (this.event.dateTo = this.event.dateFrom);
        this.event.dateTo = moment().format(DATE_FORMAT);
      }
    },
    dateFromOptionsFn(date) {
      return moment(date, DATE_FORMAT).isSameOrAfter(moment(), 'day');
    },
    dateToOptionsFn(date) {
      if (!this.event.dateFrom)
        return moment(date, DATE_FORMAT).isSameOrAfter(moment(), 'day');
      return (
        moment(date, DATE_FORMAT).isSameOrAfter(moment(), 'day') &&
        moment(date, DATE_FORMAT).isSameOrAfter(
          moment(this.event.dateFrom, DATE_FORMAT),
          'day'
        )
      );
    },
    recurrentDateToOptionsFn(date) {
      if (!this.event.firstDate)
        return moment(date, DATE_FORMAT).isAfter(moment(), 'day');
      return (
        moment(date, DATE_FORMAT).isAfter(moment(), 'day') &&
        moment(date, DATE_FORMAT).isAfter(
          moment(this.event.firstDate, DATE_FORMAT),
          'day'
        )
      );
    },
    setDayFrom($event, offset) {
      $event.stopPropagation();
      $event.preventDefault();
      this.event.dateFrom = moment()
        .add(parseInt(offset), 'days')
        .format(DATE_FORMAT);
    },
    setDayTo($event, offset) {
      $event.stopPropagation();
      $event.preventDefault();
      this.event.dateTo = moment(this.event.dateFrom || new Date(), DATE_FORMAT)
        .add(parseInt(offset), 'days')
        .format(DATE_FORMAT);
    },
    setDayRecurrenceTo($event, offset) {
      $event.stopPropagation();
      $event.preventDefault();
      this.event.recurrenceTo = moment()
        .add(parseInt(offset), 'days')
        .format(DATE_FORMAT);
    },

    checkRecurrenceValidity() {
      if (
        this.event.recurrentPreset && 
        this.event.recurrenceTo &&
        this.isDateFromValid &&
        this.isTimeToValid &&
        this.isRecurrentDateToValid &&
        !this.recurrentValidation.length
      ) {
        this.isValidRecurrent = true;

        this.inProgress = true;
        this.recurrentEventComputedData = null;
        this.testRecurrentEvent().then(
          (res) => {
            this.inProgress = false;
            const data = res.data.data.checkRecurrentDates;
            if (data && data.dates)
              data.dates = data.dates.map((i) => i.replaceAll('-', '/'));
            this.recurrentEventComputedData = data;
          },
          () => {
            this.inProgress = false;
            // this.recurrentEventComputedData = null;
          }
        );
      } else {
        this.isValidRecurrent = false;
      }
    },

    // this will test the recurrence by calling api endpoint
    testRecurrentEvent() {
      const options = {
        firstDate: moment(this.event.firstDate, DATE_FORMAT)
          .tz(this.event.timezone)
          .setTime(this.event.timeFrom)
          .format(), //, this.event.timezone).format(), // required	Dates of start and finish(optional) of the event. Use this format of date: `YYYY-MM-DDThh:mm[:ss[.uuuuuu]][+HH:MM|-HH:MM|Z]`
        firstDateTo: moment(this.event.firstDate, DATE_FORMAT)
          .tz(this.event.timezone)
          .setTime(this.event.timeTo)
          .format(), //, this.event.timezone).format(), // required	Dates of start and finish(optional) of the event. Use this format of date: `YYYY-MM-DDThh:mm[:ss[.uuuuuu]][+HH:MM|-HH:MM|Z]`
        lastPossibleDate: moment(this.event.recurrenceTo, DATE_FORMAT).format(),
        recurrentType: this.event.recurrentType,
        recurrentEvery:
          this.event.recurrentType === 'MONTHLY'
            ? 1
            : this.event.recurrentFrequency,
        recurrentDailyWeekends: this.event.recurrentDailyDetails,
        recurrentMonthlyBy:
          this.event.recurrentType === 'MONTHLY'
            ? this.event.recurrentMonthlyType
            : null,
        recurrentWeeklyDays: this.event.recurrentWeeklyDetails,
        recurrentMonthlyByDate: this.event.recurrentMonthlyDates.sort(
          (a, b) => a - b
        ),
        recurrentMonthlyByEach: this.event.recurrentMonthlyEach.sort(
          (a, b) => a - b
        ),
      };
      return Events.testRecurrent(options);
    },

    // prefill recurrent frequency
    prefillRecurrent(recurrentType) {
      let options = [];
      if (recurrentType == 'DAILY') {
        options = Array.from(Array(7)).map((key, i) => {
          return {
            name: this.$i18n.t('events.create.recurrence_every_nth_day', {
              count: i > 0 ? this.getLocaleNth(i + 1) : '',
            }),
            code: i + 1,
          };
        });
      }

      if (recurrentType == 'WEEKLY') {
        options = Array.from(Array(4)).map((key, i) => {
          return {
            name: this.$i18n.t('events.create.recurrence_every_nth_week', {
              count: i > 0 ? this.getLocaleNth(i + 1) : '',
            }),
            code: i + 1,
          };
        });
      }
      this.recurrentFrequencyOptions = options;

      if (recurrentType == 'MONTHLY') {
        this.recurrentMonthlyOptions = [
          {
            name: this.$i18n.t('events.create.recurrence_monthly_by_date'),
            code: 'DATE',
          },
          {
            name: this.$i18n.t('events.create.recurrence_monthly_by_day'),
            code: 'EACH',
          },
        ];
      }
    },

    loadRecurrentData() {
      // recurrentMonthlyBy: "EACH"
      // recurrentMonthlyByDate: [1, 22]
      // recurrentMonthlyByEach: [1]
      // recurrentType: "MONTHLY"
      // recurrentWeeklyDays: ["SUNDAY", "MONDAY", "TUESDAY"]
      return new Promise((resolve) => {
        Events.loadRecurrentDetails(this.event.id).then((res) => {
          if (res && res.data && res.data.data && res.data.data.getEvent) {
            const data = res.data.data.getEvent.recurrentV2Parent;
            if (!data) throw new Error('This event has no recurrent data');
            this.event.firstDate = moment(res.data.data.getEvent.date).format(
              DATE_FORMAT
            );
            this.event.recurrenceTo = moment(data.lastPossibleDate).format(
              DATE_FORMAT
            );
            this.event.recurrentType = data.recurrentType;
            this.event.recurrentPreset = data.fePreset || 'CUSTOM';
            this.event.recurrentMonthlyType = data.recurrentMonthlyBy;
            setTimeout(() => {
              // wait for watchers and computed
              this.event.recurrentFrequency = data.recurrentEvery;
              this.event.recurrentDailyDetails = data.recurrentDailyWeekends;
              this.event.recurrentWeeklyDetails = data.recurrentWeeklyDays;
              this.event.recurrentMonthlyDates = data.recurrentMonthlyByDate;
              this.event.recurrentMonthlyEach = data.recurrentMonthlyByEach;
              resolve(true);
            });
          }
        });
      });
    },

    getLocaleNth(number) {
      if (this.$i18n.locale.toLowerCase() === 'es') return number;
      return moment('2020-01-' + String(number).padStart(2, '0')).format('Do');
    },
    
    ensurePreset(preset) {
      if (preset === 'DAILY') {
        this.event.recurrentType = 'DAILY';
        this.$nextTick(() => {
          this.event.recurrentFrequency = 1;
          this.event.recurrentDailyDetails = true;
        });
      }
      if (preset === 'WEEKLY') {
        this.event.recurrentType = 'WEEKLY';
        this.$nextTick(() => {
          this.event.recurrentFrequency = 1;
          
          if (!this.event.firstDate) return;
          const engDayName = moment(this.event.firstDate, DATE_FORMAT).locale('en').format('dddd').toUpperCase();
  
          this.event.recurrentWeeklyDetails = [engDayName]; 
        })
      }
      if (preset === 'MONTHLY') {
        this.event.recurrentType = 'MONTHLY';
        this.$nextTick(() => {
          this.event.recurrentFrequency = 1;
          this.event.recurrentMonthlyType = 'DATE';
          const dayNumber = moment(this.event.firstDate, DATE_FORMAT).locale('en').format('D')
          this.event.recurrentMonthlyDates = [parseInt(dayNumber)]; // TODO choose start days day
        })
      }
      // if (preset === 'CUSTOM') {}
      if (preset) setTimeout(this.checkRecurrenceValidity, 100);
    }
  },
  computed: {
    isDateFromValid() {
      if (!this.event.dateFrom) return true;
      const selectedDate = moment(this.event.dateFrom, DATE_FORMAT);

      return (
        selectedDate.isValid() && selectedDate.isSameOrAfter(moment(), 'day')
      );
    },
    isDateToValid() {
      if (!this.event.dateTo) return true;
      if (!this.event.dateFrom) return false;
      const selectedDate = moment(this.event.dateTo, DATE_FORMAT);

      return (
        selectedDate.isValid() &&
        selectedDate.isSameOrAfter(moment(), 'day') &&
        selectedDate.isSameOrAfter(
          moment(this.event.dateFrom, DATE_FORMAT),
          'day'
        )
      );
    },
    isRecurrentDateToValid() {
      if (!this.event.recurrenceTo) return true;
      if (!this.event.firstDate) return false;
      const selectedDate = moment(this.event.recurrenceTo, DATE_FORMAT);
      return (
        selectedDate.isValid() &&
        selectedDate.isAfter(moment(), 'day') &&
        selectedDate.isAfter(moment(this.event.firstDate, DATE_FORMAT), 'day')
      );
    },
    isTimeToValid() {
      if (!this.event.timeTo || !this.event.dateTo) return true;
      // time to valid = set time of end > set time of start
      let dateTo = moment(this.event.dateTo, DATE_FORMAT).clone();
      let dateFrom = moment(this.event.dateFrom, DATE_FORMAT).clone();
      return (
        this.event.dateFrom &&
        this.event.dateTo &&
        this.event.timeTo &&
        dateTo
          .setTime(this.event.timeTo)
          .isAfter(dateFrom.setTime(this.event.timeFrom))
      );
    },

    recurrentRepeatString() {
      if (this.recurrentValidation.length) {
        return 'Error - validation failed';
      }

      let str = '';
      const dates = {
        dateFrom: moment(this.event.firstDate, DATE_FORMAT)
          .locale(this.$i18n.locale)
          .format('L'),
        timeFrom: this.event.timeFrom,
        timeTo: this.event.timeTo,
        dateTo: moment(this.event.recurrenceTo, DATE_FORMAT)
          .locale(this.$i18n.locale)
          .format('L'),
      };

      // returns Nth form of number (1st or 1., 2nd or 2.) depending on locale

      if (this.event.recurrentType === 'DAILY') {
        const dayNumberStr =
          this.event.recurrentFrequency === 1
            ? ''
            : this.getLocaleNth(this.event.recurrentFrequency);
        const weekendStr =
          this.event.recurrentDailyDetails === true
            ? this.$i18n.t('events.create.recurrent_preview.including_weekends')
            : this.$i18n.t(
                'events.create.recurrent_preview.excluding_weekends'
              );

        str = this.$i18n.t(
          'events.create.recurrent_preview.day_repeat_string',
          {
            dayNumber: dayNumberStr,
            weekends: weekendStr,
            ...dates,
          }
        );
      }

      if (this.event.recurrentType === 'WEEKLY') {
        const weekNumberStr =
          this.event.recurrentFrequency === 1
            ? ''
            : this.getLocaleNth(this.event.recurrentFrequency);
        const weekDaysStr = this.event.recurrentWeeklyDetails
          .map((i) =>
            this.$i18n.t('events.create.recurrent_preview.on_week_days.' + i)
          )
          .join(
            ' ' + this.$i18n.t('events.create.recurrent_preview.and') + ' '
          );

        str = this.$i18n.t(
          'events.create.recurrent_preview.week_repeat_string',
          {
            weekNumber: weekNumberStr,
            weekDays: weekDaysStr,
            ...dates,
          }
        );
      }

      if (this.event.recurrentType === 'MONTHLY') {
        if (this.event.recurrentMonthlyType === 'EACH') {
          const weekNumberStr = this.event.recurrentMonthlyEach
            .map((i) => this.getLocaleNth(i))
            .join(
              ' ' + this.$i18n.t('events.create.recurrent_preview.and') + ' '
            );
          const weekDaysStr = this.event.recurrentWeeklyDetails
            .map((i) =>
              this.$i18n.t('events.create.recurrent_preview.on_week_days.' + i)
            )
            .join(
              ' ' + this.$i18n.t('events.create.recurrent_preview.and') + ' '
            );

          str = this.$i18n.t(
            'events.create.recurrent_preview.month_repeat_by_each_string',
            {
              weekNumber: weekNumberStr,
              weekDays: weekDaysStr,
              ...dates,
            }
          );
        }

        if (this.event.recurrentMonthlyType === 'DATE') {
          const dayNumberStr = this.event.recurrentMonthlyDates
            .map((i) =>
              i === -1
                ? this.$i18n.t('events.create.recurrent_preview.last')
                : this.getLocaleNth(i)
            )
            .join(
              ' ' + this.$i18n.t('events.create.recurrent_preview.and') + ' '
            );

          str = this.$i18n.t(
            'events.create.recurrent_preview.month_repeat_by_date_string',
            {
              dayNumber: dayNumberStr,
              ...dates,
            }
          );
        }
      }
      return str;
    },
    recurrentValidation() {
      let ret = [];
      if (!this.event.recurrentType) ret.push('recurrenceTypeMissing');

      if (!this.event.recurrentFrequency) {
        if (
          this.event.recurrentType === 'MONTHLY' &&
          !this.event.recurrentMonthlyType
        )
          ret.push('recurrenceFrequencyMissing');
        if (this.event.recurrentType !== 'MONTHLY')
          ret.push('recurrenceFrequencyMissing');
      }

      if (
        this.event.recurrentType === 'DAILY' &&
        this.event.recurrentDailyDetails == null
      )
        ret.push('recurrenceDailyOptionsMissing');

      if (
        this.event.recurrentType === 'WEEKLY' &&
        (!this.event.recurrentWeeklyDetails ||
          !this.event.recurrentWeeklyDetails.length)
      )
        ret.push('recurrenceWeeklyOptionsMissing');



      if (this.event.recurrentType === 'MONTHLY') {
        if (
          this.event.recurrentMonthlyType === 'EACH' &&
          (!this.event.recurrentMonthlyEach ||
            !this.event.recurrentMonthlyEach.length)
        )
          ret.push('recurrenceMonthlyOptionsMissing');
        if (
          this.event.recurrentMonthlyType === 'EACH' &&
          (!this.event.recurrentWeeklyDetails ||
            !this.event.recurrentWeeklyDetails.length)
        )
          ret.push('recurrenceWeeklyOptionsMissing');
        if (
          this.event.recurrentMonthlyType === 'DATE' &&
          (!this.event.recurrentMonthlyDates ||
            !this.event.recurrentMonthlyDates.length)
        )
          ret.push('recurrenceMonthlyOptionsMissing');
      }
      if (!this.isRecurrentDateToValid) ret.push('recurrenceDateToInvalid');
      this.$emit('recurrency-validation', ret);
      return ret;
    },

    // checks whether events duration is less than 3 months
    isEventDurationOk() {
      const months = 3;
      if (this.isDateFromValid && this.isDateToValid) {
        const durationMs = new Date(this.event.dateTo).getTime() - new Date(this.event.dateFrom).getTime();
        const durationMonths = durationMs / 1000 / 60 / 60 / 24 / (30 * months);
        return durationMonths < 1
      } else {
        return null
      }
    },
  },
  watch: {
    'recurrentEventComputedData'() {
      this.$emit('recurrency-events', this.recurrentEventComputedData);
    },
    'event.dateFrom'(newval, oldval) {
      if (newval === null) {
        this.event.dateFrom = oldval;
      }
      this.$emit('date-validation', { duration: this.isEventDurationOk });
    },
    'event.dateTo'(newval, oldval) {
      if (newval === null) {
        this.event.dateTo = oldval;
      }
      this.$emit('date-validation', { duration: this.isEventDurationOk });
    },
    'event.firstDate'(newval) {
      if (newval && this.event.recurrentPreset) {
        this.ensurePreset(this.event.recurrentPreset)
      }
    },
    'event.recurrenceTo'(newval) {
      if (newval && this.event.recurrentPreset) {
        this.ensurePreset(this.event.recurrentPreset);
      }
    },
    'event.isRecurrent'(newval) {
      if (this.isEditing && newval === true) {
        // during editation - when changing to recurrent --> load additional recurrent info
        this.loadRecurrentData().then(() => {
          setTimeout(this.checkRecurrenceValidity, 100);
        });
      }
    },
    'event.recurrentType'(type, oldval) {
      if (type != oldval) {
        this.prefillRecurrent(type);
        // this.event.recurrentFrequency = null;
        this.event.recurrentDailyDetails = null;
        this.event.recurrentWeeklyDetails = [];
        this.event.recurrentMonthlyDates = [];
        this.event.recurrentMonthlyEach = [];
      }
    },
    'event.recurrentPreset'(newval) {
      if (newval) {
        this.ensurePreset(newval)
      }
    }
  },
  mounted() {
    // prefill weeks and days
    this.recurrentMonthlyDateOptions = Array.from(Array(31)).map((k, i) => {
      return {
        name: this.$i18n.t('events.create.recurrence_every_nth_day', {
          count: this.getLocaleNth(i + 1),
        }),
        code: i + 1,
      };
    });
    this.recurrentMonthlyDateOptions.push({
      name: this.$i18n.t('events.create.recurrence_every_last_day'),
      code: -1,
    });

    this.recurrentWeeklyOptions = this.locale.days.map((i, k) => {
      return {
        name: i,
        code: getDayNames('en')[k].toUpperCase(), //force english names for API!
      };
    });

    this.recurrentMonthlyEachOptions = Array.from(Array(5)).map((k, i) => {
      return {
        name: this.$i18n.t('events.create.recurrence_every_nth_week_of_month', {
          count: this.getLocaleNth(i + 1),
        }),
        code: i + 1,
      };
    });

    if (this.event.recurrentType) {
      this.prefillRecurrent(this.event.recurrentType);
      setTimeout(this.checkRecurrenceValidity, 100);
    }
  },
  created() {},
  beforeDestroy() {
    this.$emit('recurrency-string', this.recurrentRepeatString);
  },
};
