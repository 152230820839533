import { render, staticRenderFns } from "./Date.vue?vue&type=template&id=a7036596&scoped=true&"
import script from "./Date.vue?vue&type=script&lang=js&"
export * from "./Date.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a7036596",
  null
  
)

export default component.exports
import {QRadio,QSelect,QIcon,QInput,QPopupProxy,QDate,QTime,QBtn,QDialog,QCard,QCardSection,QBanner} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QRadio,QSelect,QIcon,QInput,QPopupProxy,QDate,QTime,QBtn,QDialog,QCard,QCardSection,QBanner})
